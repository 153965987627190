app-image-annotation-modal {
    display: block;
    width: 100%;
    height: calc(100vh - 1.75rem - 1.75rem);

    .image-annotation {
        height: 100%;
        width: 100%;
    }
}

.image-annotation {
    position: relative;
    background-color: black;
    display: flex;
    flex-direction: column;

    &__header {
        display: flex;
        padding: .5rem 1rem;
        align-items: center;

        &__button {
            transition: all 200ms;
            background-color: #000;
            border: solid 2px #000;
            color: #fff;
            font-size: 1.1rem;

            &:disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }

            & svg {
                height: 2rem;
                width: 2rem;
            }
        }
    }

    &__container {
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
    }

    &__footer {
        display: flex;
        padding: .5rem;
        align-items: center;
    }

    &__tools, &__palette {
        flex-grow: 1;

        display: flex;
        flex-wrap: wrap;
    }

    &__tools {
        &__tool {
            transition: all 200ms;
            margin: .5rem;
            padding: .5rem;

            cursor: pointer;

            border-radius: 50%;
            border: solid 2px white;
            background-color: black;
            color: white;

            & > svg {
                height: 3rem;
                width: 3rem;
            }

            &--active, &:hover, &:focus {
                background-color: white;
                color: black;
            }

            &--active {
                box-shadow: 0 0 .5rem .25rem #fff;
            }
        }
    }

    &__palette {
        margin-left: auto;
        justify-content: flex-end;
        max-width: calc(5 * 3rem);

        &__color {
            --color: #fff;
            height: 2rem;
            width: 2rem;
            margin: .5rem;

            transition: all 200ms;
            background-color: var(--color);
            border-radius: 50%;
            border: solid 2px var(--color);

            &--active, &:hover, &:focus {
                border-color: white;
            }

            &--active {
                box-shadow: 0 0 .5rem .25rem #fff;
            }
        }
    }
}
