/*
  Generates utitlity classes for space system
  Spaces are defined in 1.settings layer.

  Format is:

  u- : Prefix for utility classes
  [m-p][a-t-b-l-r] : margin or padding following by a side (all, top, bottom, left, right);
  --[s-m-l-...] : Modifier for space in space system.

  Example for margin top with small modifier: u-mt--s
*/

$space-sides: (
  a: '',
  t: '-top',
  b: '-bottom',
  l: '-left',
  r: '-right'
);


@each $space, $spaceValue in $vwui-spaces {
  @each $side, $sideValue in $space-sides {
    .u-m#{$side}-#{$space} {
      margin#{$sideValue}: #{$spaceValue} !important;
    }

    .u-p#{$side}-#{$space} {
      padding#{$sideValue}: #{$spaceValue} !important;
    }
  }
}
