.btn {
    height: 50px;
    border-radius: 9999px;
    border: none;
    font-family: Roboto-Medium, sans-serif;
    font-size: 15px;
    outline: none;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    &:disabled {
        background-color: theme-color(pale-gray);
    }
}

.btn-primary {
    color: theme-color(primary);
    background: rgba(theme-color(primary), 0.1);
    padding: 0 20px;

    &:hover {
        background: rgba(theme-color(primary), 0.15);
    }

    &:active {
        background: rgba(theme-color(primary), 0.2);
    }
}
