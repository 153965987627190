.vwui-tabs {
  border-bottom: $vwui-border-outside;
  margin-bottom: $vwui-space-l;
  min-height: rem(64px);

  &__item {
    border: 0;
    background: none;
    padding: 0 $vwui-space-s;
    margin-right: $vwui-space-m;
    color: $vwui-color-text-label;
    transition: all $vwui-transition-base;
    height: rem(64px);

    &:hover {
      color: darken($vwui-color-text-label, 25%);
    }

    &.is-active {
      color: $vwui-color-primary-base;
      border-bottom: 2px solid $vwui-color-primary-base;
      font-weight: $vwui-font-weight-secondary;
    }
  }
}
