$card-shadow: $vwui-shadow-base, 0 -3px 0 0 $vwui-color-primary-base;

.vwui-card {
    box-shadow: $card-shadow;
    background: $vwui-color-bg-base;
    border-radius: $vwui-border-radius-base;
    border: $vwui-border-outside;
    border-top: 0;
    margin-top: 3px; // Offset shadow

    --card-padding: #{$vwui-space-component};

    &.is-basic {
        box-shadow: $vwui-shadow-base;
        margin-top: 0;
        border-top: $vwui-border-outside;
    }

    &.is-small {
        --card-padding: #{$vwui-space-m};
    }

    &.content-centered .vwui-card__content {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__header,
    &__content {
        padding: var(--card-padding);
        border-bottom: $vwui-border-inside;
    }

    &__header {
        display: flex;
        font-size: $vwui-font-size-l;
        font-weight: $vwui-font-weight-secondary;
        font-family: $vwui-font-secondary;
        align-items: center;
        justify-content: space-between;

        &.has-action {
            cursor: pointer;

            &:hover {
                background-color: $vwui-color-bg-hover;
            }
        }

        &.is-selected {
            background-color: $vwui-color-bg-hover;
        }
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        padding: $vwui-space-component;
    }

    &__scroll {
        max-height: 50vh;
        overflow-y: auto;
        border-bottom: $vwui-border-inside;
    }
}
