/*
  Override specific Bootstrap variables with VWUI variables
 */

$theme-colors: (
  "primary": $vwui-color-primary-base,
  "success": $vwui-color-success-base
);

$body-bg: $vwui-color-bg-body;
$body-color: $vwui-color-text-base;
$border-color: $vwui-color-border-outside;

$box-shadow: $vwui-shadow-base;

$font-family-base: $vwui-font-body;
$font-weight-base: $vwui-font-weight-base;

$grid-gutter-width: $vwui-space-grid-gutter-lg;

$border-radius: $vwui-border-radius-base;
$border-radius-lg: $vwui-border-radius-l;
$border-radius-sm: $vwui-border-radius-s;


