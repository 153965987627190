.vwui-options {
  display: flex;
  margin-bottom: rem(5px);

  &__option {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    border: $vwui-border-outside;
    cursor: pointer;
    height: rem(45px);

    &:first-child {
      border-top-left-radius: $vwui-border-radius-base;
      border-bottom-left-radius: $vwui-border-radius-base;
    }

    &:last-child {
      border-top-right-radius: $vwui-border-radius-base;
      border-bottom-right-radius: $vwui-border-radius-base;
    }

    &.is-active {
      border-color: $vwui-color-primary-base;
      cursor: default;
    }

    input {
      display: none;
    }
  }
}