html, body {
    height: 100%;
    font-family: 'Roboto', sans-serif;
}

hr {
    width: 100%;
    height: 1px;
    margin: 0;
    border-top: none;
    background-color: $border-color;
}
