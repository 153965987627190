$input-height: rem(45px);
$select-bg-position: calc(100% - #{$vwui-space-component-horizontal-s});

.vwui-label {
    display: block;
    align-items: center;
    color: $vwui-color-text-label;
    font-weight: $vwui-font-weight-secondary;
    margin-bottom: $vwui-space-s;

    &.has-error {
        color: $vwui-color-danger-base;
    }
}

.vwui-form-group {
    .vwui-textarea {
        padding: $vwui-space-component-vertical-s $vwui-space-component-horizontal-s;
    }

    &__description {
        display: block;
        margin-top: rem(5px);
        color: $vwui-color-text-label;

        .has-error & {
            color: $vwui-color-danger-base;
        }
    }

    &__select {
        position: relative;
        box-shadow: 0 1px 2px 0 rgba(216, 222, 229, .5);

        .vwui-select {
            @include icon-bg('select', $select-bg-position, center);
            background-size: rem(20px) rem(20px);
        }
    }

    &.has-error {
        label {
            color: $vwui-color-danger-base;
        }

        .vwui-input {
            border-color: $vwui-color-danger-base;
        }
    }
}

.vwui-form-group__helper-text {
    line-height: 1.35;
    color: $vwui-color-text-label;
}

.vwui-input-control {
    .vwui-input {
        display: none;
    }

    .vwui-input:checked ~ span::before {
        border-color: $vwui-color-primary-base;
    }

    .vwui-input:disabled ~ span::before {
        background-color: $vwui-color-bg-disabled;
    }

    span {
        position: relative;
        display: inline-flex;
        align-items: center;
        transition: all $vwui-transition-base;
        padding-left: rem(30px);

        @include disable-text-selection();
    }

    &.is-checkbox,
    &.is-radio {
        span::before {
            display: inline-block;
            position: absolute;
            cursor: pointer;
            left: 0;
            content: "";
            width: rem(20px);
            height: rem(20px);
            border-radius: $vwui-border-radius-s;
            border: $vwui-border-outside;
            margin-right: rem(10px);
            transition: all $vwui-transition-base;
        }
    }

    &.is-checkbox {
        .vwui-input:checked ~ span::after {
            transform: scale(1);
        }

        span::after {
            display: inline-block;
            position: absolute;
            left: 4px;
            content: "";
            width: rem(14px);
            height: rem(14px);
            transform: scale(0);
            transition: all $vwui-transition-timing-slow $vwui-transition-easeInOutBack;

            @include icon-bg('checkbox', center, center);
        }
    }

    &.is-radio {
        .vwui-input:checked ~ span::after {
            transform: scale(1);
        }

        span::before {
            border-radius: 100%;
        }

        span::after {
            display: inline-block;
            position: absolute;
            left: 6px;
            content: "";
            width: rem(8px);
            height: rem(8px);
            border-radius: 100%;
            background-color: $vwui-color-primary-base;
            transform: scale(0);
            transition: all $vwui-transition-timing-slow $vwui-transition-easeInOutBack;
        }
    }

    &.is-toggle {
        .vwui-input:checked ~ span::before {
            background-color: $vwui-color-primary-base;
        }

        .vwui-input:checked ~ span::after {
            transform: translate(calc(200% - 8px), -50%);
        }

        .vwui-input:disabled ~ span::after {
            background-color: $vwui-color-border-outside;
        }

        .vwui-input:disabled ~ span {
            cursor: not-allowed;
        }

        span {
            padding-left: 0;
            color: $vwui-color-text-label;
            font-weight: $vwui-font-weight-secondary;
            display: block;
        }

        span.checkbox {
            flex-shrink: 0;
            width: rem(70px);
            height: rem(36px);
            margin-top: rem(8px);
        }

        .checkbox::before {
            content: "";
            position: absolute;
            background-color: $vwui-color-bg-inactive;
            border-radius: rem(50px);
            cursor: pointer;
            padding-left: 0;
            width: 100%;
            height: 100%;
        }

        .checkbox::after {
            content: "";
            position: absolute;
            left: 8px;
            width: rem(20px);
            height: rem(20px);
            background-color: $vwui-color-bg-base;
            border-radius: 100%;
            transform: translate(0, -50%);
            transition: transform $vwui-transition-timing-slow $vwui-transition-easeInOutBack;
            will-change: transform;
        }

        &.has-label {
            span::after {
                top: 50%;
            }
        }
    }
}

.vwui-input,
.vwui-select,
.vwui-textarea {
    width: 100%;
    border-radius: $vwui-border-radius-base;
    border: $vwui-border-outside;
    padding: 0 $vwui-space-component-horizontal-s;
    background-color: $vwui-color-bg-base;
    color: $vwui-color-text-base;
    transition: all $vwui-transition-base;
    outline: 0;

    @include remove-appearance();

    &:focus {
        border-color: $vwui-color-primary-base;
    }

    &:disabled {
        background-color: $vwui-color-bg-disabled;
        cursor: not-allowed;
        color: $vwui-color-text-disabled;
    }

    &[type='color'] {
        padding: 0 rem(2px);
    }
}

.vwui-input,
.vwui-select {
    height: $input-height;
}

.vwui-input.is-plain {
    padding-left: 0;
    background: none;
    border: none;
    color: $vwui-color-text-base;
    cursor: default;
}

.vwui-editor {
    --ck-border-radius: #{$vwui-border-radius-base};

    &.ng-invalid {
        --ck-color-base-border: #{$vwui-color-danger-base};
        --ck-color-focus-border: #{$vwui-color-danger-base};
        --ck-focus-ring: 1px solid var(--ck-color-focus-border);
    }
}

.form-control__tags {
    margin: 0 0 0.5rem;
}

.form-control__tags-item {
    font-size: 0.9rem;
    border: 0;
    border-radius: #{$vwui-border-radius-base};
    background-color: $vwui-color-bg-body;
    color: $vwui-color-text-label;
    margin: 0 0.5rem 0.5rem 0;
    padding: 2px 8px;
    transition: color 200ms;

    &:hover {
        color: currentColor;
    }

    &::before {
        content: attr(data-tag);
    }
}

.vwui-toggle {
    position: relative;
    display: inline-flex;
    vertical-align: top;
    flex-shrink: 0;
    width: rem(70px);
    height: rem(36px);
    margin-right: rem(16px);

    .vwui-input {
        cursor: pointer;
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    }

    .vwui-input:focus-visible ~ .vwui-toggle__element::before {
        outline: 1px solid $vwui-color-primary-base;
    }

    .vwui-input:checked ~ .vwui-toggle__element::before {
        background-color: $vwui-color-primary-base;
    }

    .vwui-input:checked ~ .vwui-toggle__element::after {
        transform: translate(calc(200% - 8px), -50%);
    }

    .vwui-input:disabled ~ .vwui-toggle__element::after {
        background-color: $vwui-color-border-outside;
    }

    .vwui-input:disabled ~ .vwui-toggle__element {
        cursor: not-allowed;
    }
}

.vwui-toggle__element {
    position: relative;
    width: 100%;
    height: 100%;

    &::before {
        content: "";
        position: absolute;
        background-color: $vwui-color-bg-inactive;
        border-radius: rem(50px);
        cursor: pointer;
        padding-left: 0;
        width: 100%;
        height: 100%;
        outline: 1px solid transparent;
        outline-offset: 2px;
        transition: all 0.1s ease-in-out;
    }

    .vwui-input:checked ~ .vwui-toggle__element::after {
        transform: scale(1);
    }

    &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 8px;
        width: rem(20px);
        height: rem(20px);
        background-color: $vwui-color-bg-base;
        border-radius: 100%;
        transform: translate(0, -50%);
        transition: transform $vwui-transition-timing-slow $vwui-transition-easeInOutBack;
        will-change: transform;
    }
}
