$aside-actionbar-size: rem(100px);

.vwui-aside {
    position: fixed;
    top: $vwui-size-header;
    right: 0;
    bottom: 0;
    width: $vwui-size-aside;
    background-color: $vwui-color-bg-base;
    box-shadow: rem(-10px) 0 20px 0 rgba(0, 0, 0, .05);
    z-index: $vwui-zindex-aside;
    transform: translateX(calc(100% + 20px));
    transition: transform $vwui-transition-ease $vwui-transition-timing;
    flex-direction: column;
    will-change: transform;

    &.is-open {
        transform: translateX(0);
    }
}

.vwui-aside-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: $aside-actionbar-size;
    padding: $vwui-space-l $vwui-space-m;
    overflow-y: auto;
    overflow-x: hidden;

    &.with-tabs {
        top: rem(65px);
    }
}

.vwui-aside-actionbar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    width: 100%;
    height: $aside-actionbar-size;
    align-items: center;
    padding: $vwui-space-m;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1.0));

    .vwui-btn {
        width: 100%;

        &:not(:last-child) {
            margin-right: $vwui-space-s;
        }
    }
}

.vwui-aside-tabs {
    .vwui-tabs {
        display: flex;
        width: 100%;
        margin-bottom: 0;

        .vwui-tabs__item {
            flex: 1;
            margin: 0;

        }
    }
}
