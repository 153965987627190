.vwui-badge {
  --label-color: #{$vwui-color-text-label};
  --label-bg: #{rgba($vwui-color-text-label, .05)};

  color: var(--label-color);
  background-color: var(--label-bg);
  font-size: rem(13px);
  border-radius: rem(11px);
  padding: 0 rem(6px);

  &.is-primary {
    --label-color: #{$vwui-color-primary-base};
    --label-bg: #{rgba($vwui-color-primary-base, .05)};
  }

  &.is-success {
    --label-color: #{$vwui-color-success-base};
    --label-bg: #{rgba($vwui-color-success-base, .05)};
  }

  &.is-danger {
    --label-color: #{$vwui-color-danger-base};
    --label-bg: #{rgba($vwui-color-danger-base, .05)};
  }
}
