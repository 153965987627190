.vwui-action-menu {
    position: relative;
    z-index: 99;

    &__trigger {
        display: flex;
        background: none;
        border: 0;
        align-items: center;
        justify-content: center;
        padding: rem(5px);
        margin-right: rem(-5px);

        --icon-size: #{rem(20px)};
        --icon-fill: #{$vwui-color-icon};

        &:hover {
            --icon-fill: #{$vwui-color-text-base};
        }
    }

    &__content {
        position: absolute;
        right: rem(10px);
        top: rem(20px);
        transform: scale(0);
        opacity: 0;
        transform-origin: top right;
        transition: all $vwui-transition-timing-slow $vwui-transition-easeInOutBack;
        will-change: transform, opacity;
        z-index: 10;

        &.is-open {
            transform: scale(1);
            opacity: 1;
        }

        &.grow-upward {
            top: initial;
            bottom: rem(20px);
            transform-origin: bottom right;
        }
    }
}
