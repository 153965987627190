.vwui-menu {
    background: $vwui-color-bg-base;
    border: $vwui-border-outside;
    border-radius: $vwui-border-radius-base;
    box-shadow: $vwui-shadow-base;
    overflow: hidden;
}

.vwui-menu-item {
    --icon-fill: #{$vwui-color-icon};
    --icon-size: #{rem(20px)};

    display: flex;
    padding: rem(12px) rem(16px);
    transition: $vwui-transition-base;
    align-items: center;
    background: none;
    border: 0;
    width: 100%;
    font-size: $vwui-font-size-base;
    white-space: nowrap;

    &:hover {
        text-decoration: none;
        background-color: $vwui-color-bg-hover;
    }

    app-icon {
        margin-right: rem(10px);
    }

    &.is-success {
        --icon-fill: #{$vwui-color-success-base};
    }

    &.is-danger {
        --icon-fill: #{$vwui-color-danger-base};
    }
}
