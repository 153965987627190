.vwui-search {
    display: flex;
    position: relative;
    border-bottom: $vwui-border-inside;
    height: rem(70px);
    padding: 0 $vwui-space-component-horizontal;
    align-items: center;

    &__icon {
        position: absolute;
        top: 50%;
        left: $vwui-space-component-horizontal;
        transform: translateY(-50%);
        pointer-events: none;

        app-icon {
            --icon-size: #{rem(20px)};
            --icon-fill: #{$vwui-color-text-label};

            .vwui-icon {
                display: block;
            }
        }
    }

    .vwui-input {
        width: 100%;
        border: 0;
        padding-left: rem(30px);
    }

    .vwui-input:focus ~ .vwui-search__icon {
        app-icon {
            --icon-fill: #{$vwui-color-text-primary};
        }
    }
}
