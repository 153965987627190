.add-btn {
    --action-add-menu-visible: 0;

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: rem(40px);

    &::before {
        position: absolute;
        background-color: $vwui-color-border-outside;
        content: "";
        display: inline-block;
        height: 1px;
        width: 100%;
    }

    &::before {
        left: 0;
    }

    &.actions-visible {
        --action-add-menu-visible: 1;
    }

    &__container {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        width: rem(70px);
        height: rem(35px);
        cursor: pointer;
    }

    &__icon {
        display: flex;
        background: $vwui-color-bg-base;
        width: rem(35px);
        height: rem(35px);
        border-radius: 100%;
        border: 1px solid $vwui-color-border-inside;
        align-items: center;
        justify-content: center;

        --icon-fill: #{$vwui-color-success-base};
        --icon-size: #{rem(23px)};
    }

    .add-btn-actions {
        position: absolute;
        display: flex;
        height: rem(35px);
        align-items: center;
        justify-content: center;
        background-color: $vwui-color-bg-base;
        padding: 0 rem(35px);
        z-index: 10;
        opacity: var(--action-add-menu-visible);
        transition: all .35s $vwui-transition-easeInOutBack;
        transform: scale(var(--action-add-menu-visible));

        button {
            width: rem(125px);
            height: 100%;
            text-align: center;
            opacity: var(--action-add-menu-visible);
            transition: opacity .15s;
            border: 0;
            background: $vwui-color-primary-base;
            color: $vwui-color-text-contrast;

            &:first-child {
                border-top-left-radius: $vwui-border-radius-base;
                border-bottom-left-radius: $vwui-border-radius-base;
            }

            &:last-child {
                border-top-right-radius: $vwui-border-radius-base;
                border-bottom-right-radius: $vwui-border-radius-base;
            }

            &:hover {
                background: $vwui-color-primary-dark;
            }
        }
    }
}

.animated {
    position: absolute;
    top: -3px;
    width: 100%;
    transform: translateX(0);
    transition: all .35s $vwui-transition-ease;

    &.move-to-left {
        transform: translateX(calc(-100% - 50px));
        opacity: 0;
        visibility: hidden;
    }

    &.move-to-right {
        transform: translateX(calc(100% + 50px));
        pointer-events: none;
    }
}
