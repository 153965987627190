app-multi-image-upload {
    .sortable-container {
        --media-item-width: 160px;
        --media-item-height: 160px;
        @media (max-width: 375px) {
            --media-item-width: 130px;
            --media-item-height: 130px;
        }

        width: 100%;
        position: relative;
        z-index: 1;

        .icon {
            width: 28px;
            height: 28px;
        }


        .sortable-grid {
            &:before { /* Before added because the drag&drop doesnt accept non-draggable items in it's grid*/
                display: block;
                content: '';
                width: var(--media-item-width);
                height: var(--media-item-height);
            }

            position: relative;
            z-index: 1;
            display: grid;
            grid-template-columns: repeat(auto-fill, var(--media-item-width));

            grid-gap: 8px;
        }

        .sortable-grid__item {
            --bg: var(--vwui-color-bg-base);
            --color: var(--vwui-color-text-base);
            width: 100%;
            max-width: var(--media-item-width);
            height: var(--media-item-height);
            position: relative;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
            border: 1px solid #d8dee5;
            background-color: var(--bg);
            color: var(--color);
            border-radius: 3px;
            font-size: 15px;

            &--add {
                width: var(--media-item-width);
                height: var(--media-item-height);
                display: flex;
                flex-direction: column;
                z-index: 2;
                position: absolute;
                left: 0;
                top: 0;
                grid-row: 1;
                grid-column: 1;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                app-icon {
                    --icon-size: 28px;
                    margin-bottom: 4px;
                }

                &.has-error {
                    border-color: $vwui-color-danger-base;;
                }
            }
        }

        & input:disabled + .sortable-grid__item--add {
            --bg: #e9edf3;
            --color: #9aa5b5;
            cursor: not-allowed;

            & img {
                filter: grayscale(100%);
            }
        }
    }

    .image-thumbnail {
        width: 100%;
        height: 100%;
        object-fit: cover;

        &__icon {
            --icon-size: 20px;

            position: absolute;
            margin-left: -25px;
            margin-top: 10px;
            color: $vwui-color-danger-base;
            pointer-events: all;
        }
    }
}
