$vwui-space-s: rem(10px);
$vwui-space-m: rem(20px);
$vwui-space-l: rem(30px);
$vwui-space-xl: rem(50px);

$vwui-space-component-horizontal: rem(35px);
$vwui-space-component-horizontal-s: rem(20px);

$vwui-space-component-vertical: rem(20px);
$vwui-space-component-vertical-s: rem(12px);

$vwui-space-component: $vwui-space-component-vertical $vwui-space-component-horizontal;
$vwui-space-component-s: $vwui-space-component-vertical-s $vwui-space-component-horizontal-s;

$vwui-space-grid-gutter: $vwui-space-l;
$vwui-space-grid-gutter-lg: $vwui-space-xl;

$vwui-spaces: (
    none: 0,
    s: $vwui-space-s,
    m: $vwui-space-m,
    l: $vwui-space-l,
    xl: $vwui-space-xl
);
