.vwui-user {
  position: relative;
  z-index: 10;

  &__content {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__label {
    margin-right: rem(15px);

    span {
      color: $vwui-color-primary-base;
    }
  }

  &__menu {
    position: absolute;
    right: rem(20px);
    top: rem(20px);
    transform: scale(0);
    opacity: 0;
    transform-origin: top right;
    transition: all $vwui-transition-timing-slow $vwui-transition-easeInOutBack;
    will-change: transform, opacity;

    &.is-open {
      transform: scale(1);
      opacity: 1;
    }
  }
}
