.vwui-grid {
    &__column {
        flex-basis: 100%;
        margin-bottom: rem(30px);

        &:last-child {
            margin-right: 0;
        }

        &.is-fixed {
            flex-basis: rem(600px);
            flex-shrink: 0;
        }
    }

    @media screen and (min-width: em(1280)) {
        display: flex;

        &__column {
            margin-right: rem(50px);
            margin-bottom: 0;
        }
    }
}
