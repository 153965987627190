.ng-select-table {
    --ng-table-th: #{$vwui-color-text-label};
    width: 100%;

    th {
        font-size: 12px;
        color: var(--ng-table-th);
        width: 25%;
    }

    td {
        font-size: 11px;
        color: #4a6272;
        width: 25%;
        max-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.ng-select-table--tabular {
    th, td {
        padding: 0.25rem 0;
    }

    .table-header {
        border-bottom: 1px solid $vwui-color-border-inside;
    }
}
