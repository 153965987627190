.empty-state {
    display: flex;
    align-items: center;
    margin: $vwui-space-l 0;
    flex-direction: column;
    text-align: center;

    &__title {
        font-family: $vwui-font-body;
        font-size: rem(20px);
        font-weight: $vwui-font-weight-base;
    }

    &__image {
        width: rem(150px);
        margin-bottom: $vwui-space-m;
    }
}
