@import '~@arcgis/core/assets/esri/themes/light/main.css';

app-project-map {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background: rgba(0,0,0,0.7);
    .project-map-inner {
        position: absolute;
        top: 2rem;
        bottom: 2rem;
        right: 2rem;
        left: 2rem;
        border-radius: $vwui-border-radius-base;
        overflow: hidden;
        background: $vwui-color-bg-body;
    }
}

.project-map-container {
    width: 100%;
    height: 100%;
}

.project-map-close-button {
    --icon-size: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 12px;
    right: 12px;
    height: 44px;
    width: 44px;
    opacity: 0.8;
    background: $vwui-color-border-dark;
    color: #fff;
    border-radius: 50%;
}

.esri-view-width-less-than-medium .esri-popup__action-text {
    display: block;
}

.esri-popup__inline-actions-container > .esri-popup__action, .esri-popup__inline-actions-container > .esri-popup__action-toggle {
    max-width: 100% !important;
}
