.vwui-main {
  padding: $vwui-space-xl;
}

.vwui-main-actions {
  display: flex;
  margin-bottom: $vwui-space-xl;

  &__right {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
}
