.image-file-upload {
  position: relative;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  &--loading {
      .ngx-file-drop__drop-zone-label {
          display: none;
      }
  }

  &__image-container {
    position: relative;
    width: 100%;
  }

  &__image {
    width: auto;
    max-width: 100%;
    height: rem(175px);
    object-fit: contain;
    border-radius: $vwui-border-radius-s;
  }

  &__image-remove-btn {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    width: rem(40px);
    height: rem(40px);
    border-bottom-left-radius: $vwui-border-radius-s;
    background-color: rgba(#222f3e, 0.8);
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;

    app-icon {
      --icon-size: #{rem(20px)}
    }
  }

  &__file-drop {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    .ngx-file-drop__drop-zone {
      position: relative;
      border-radius: $vwui-border-radius-base !important;
      border: 2px dotted $vwui-color-border-outside !important;
    }

    .ngx-file-drop__content {
      padding: $vwui-space-component-s;
      color: $vwui-color-text-label !important;
    }

    .ngx-file-drop__browse-btn {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
}
