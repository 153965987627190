$btn-size: 45px;
$btn-border-radius: rem(6px);

.vwui-btn {
    // CSS Custom properties for easy modifying different button states
    --btn-bg: #{$vwui-color-bg-base};
    --btn-bg-hover: #{$vwui-color-bg-base};
    --btn-border: #{$vwui-border-base};
    --btn-border-color: #{$vwui-color-border-outside};
    --btn-border-color-hover: #{$vwui-color-border-dark};
    --btn-space: #{$vwui-space-component-vertical-s} #{$vwui-space-component-horizontal-s};
    --btn-color: #{$vwui-color-text-base};
    --btn-color-hover: #{$vwui-color-text-base};
    --btn-font-weight: #{$vwui-font-weight-base};
    --btn-focus-shadow-color: #{rgba($vwui-color-border-outside, 0.75)};
    --icon-fill: #{$vwui-color-text-label};
    --icon-size: #{rem(20px)};
    --icon-border-color: #{$vwui-color-border-outside};

    display: inline-flex;
    cursor: pointer;
    position: relative;
    height: rem($btn-size);
    border-radius: $btn-border-radius;
    vertical-align: top;
    align-items: center;
    justify-content: center;
    transition: all $vwui-transition-base;

    border: var(--btn-border) var(--btn-border-color);
    background: var(--btn-bg);
    padding: var(--btn-space);
    color: var(--btn-color);
    font-weight: var(--btn-font-weight);
    will-change: transform;

    @include text-ellipsis();

    &:focus {
        // box-shadow: 0 0 0 3px var(--btn-focus-shadow-color);
    }

    &:active {
        // transform: scale(0.994) translateZ(0);
        box-shadow: none;
    }

    &.has-icon {
        padding-left: rem($btn-size + 15);

        app-icon {
            display: flex;
            position: absolute;
            top: 0;
            left: 0;
            border-right: var(--btn-border) var(--icon-border-color);
            background: var(--btn-bg-hover);
            transform: scale(0.99999, 0.99999) translateZ(0); // Fix bug in Chrome if transform scale is applied

            width: rem($btn-size);
            height: rem($btn-size);

            @include flex-center();
        }
    }

    &:hover {
        text-decoration: none;
        background: var(--btn-bg-hover);
        color: var(--btn-color-hover);
        border: var(--btn-border) var(--btn-border-color-hover);
    }

    &:disabled,
    &.is-disabled {
        // CSS Vars must always be important if button is disabled
        --btn-bg: #{$vwui-color-bg-disabled} !important;
        --btn-bg-hover: #{$vwui-color-bg-disabled} !important;
        --btn-color: #{$vwui-color-text-disabled} !important;
        --btn-color-hover: #{$vwui-color-text-disabled} !important;
        --btn-border-color: transparent !important;
        --btn-border-color-hover: transparent !important;

        cursor: not-allowed;
        pointer-events: none;
    }

    &.is-primary {
        --btn-bg: #{$vwui-color-primary-base};
        --btn-bg-hover: #{$vwui-color-primary-dark};
        --btn-color: #{$vwui-color-text-contrast};
        --btn-color-hover: #{$vwui-color-text-contrast};
        --btn-border-color: transparent;
        --btn-border-color-hover: #{$vwui-color-primary-dark};
        --btn-font-weight: #{$vwui-font-weight-secondary};
        --btn-focus-shadow-color: #{rgba($vwui-color-primary-base, 0.25)};
        --icon-border-color: var(--btn-border-color-hover);
        --icon-fill: var(--btn-color);

        .vwui-spinner {
            --loader-color: #{rgba($vwui-color-text-contrast, 0.2)};
            --loader-color-spinner: #{rgba($vwui-color-text-contrast, 1.0)};
        }
    }

    &.is-success {
        --btn-bg: #{$vwui-color-success-base};
        --btn-bg-hover: #{$vwui-color-success-dark};
        --btn-color: #{$vwui-color-text-contrast};
        --btn-color-hover: #{$vwui-color-text-contrast};
        --btn-border-color: transparent;
        --btn-border-color-hover: #{$vwui-color-success-dark};
        --btn-font-weight: #{$vwui-font-weight-secondary};
        --btn-focus-shadow-color: #{rgba($vwui-color-success-base, 0.25)};
        --icon-border-color: var(--btn-border-color-hover);
        --icon-fill: var(--btn-color);

        .vwui-spinner {
            --loader-color: #{rgba($vwui-color-text-contrast, 0.2)};
            --loader-color-spinner: #{rgba($vwui-color-text-contrast, 1.0)};
        }
    }

    &.is-danger {
        --btn-border-color-hover: #{$vwui-color-danger-base};
        --icon-fill: #{$vwui-color-danger-base};
    }

    &.is-danger-full {
        --btn-bg: #{$vwui-color-danger-base};
        --btn-bg-hover: #{$vwui-color-danger-dark};
        --btn-color: #{$vwui-color-text-contrast};
        --btn-color-hover: #{$vwui-color-text-contrast};
        --btn-border-color: transparent;
        --btn-border-color-hover: #{$vwui-color-danger-dark};
        --btn-font-weight: #{$vwui-font-weight-secondary};
        --btn-focus-shadow-color: #{rgba($vwui-color-danger-base, 0.25)};
        --icon-border-color: var(--btn-border-color-hover);
        --icon-fill: var(--btn-color);
    }

    &.is-back {
        --btn-bg: transparent;
        --btn-bg-hover: transparent;
        --icon-border-color: transparent;
        flex-shrink: 0;

        &.has-icon {
            padding-left: rem($btn-size);
        }
    }

    &.is-block {
        width: 100%;

        &.has-icon {
            padding-left: rem($btn-size);
        }
    }

    &.is-login {
        --btn-color-hover: var(--btn-color);
        --btn-bg: linear-gradient(
            to right,
            var(--btn-bg-left, #{$vwui-color-primary-base}),
            var(--btn-bg-right, #{$vwui-color-primary-base})
        );
        --btn-bg-hover: linear-gradient(
                to right,
                var(--btn-bg-left, #{$vwui-color-primary-base}),
                var(--btn-bg-right, #{$vwui-color-primary-base})
        );
    }

    &.has-loader {
        padding-left: rem($btn-size + 15);
    }

    .vwui-spinner {
        position: absolute;
        top: 0;
        left: 0;
    }
}

.vwui-btn-no-bg {
    background: none;
    border: none;
}

.vwui-icon-btn {
    background: none;
    border: 0;
    color: $vwui-color-text-label;
    --icon-size: #{rem(20px)};

    &:hover {
        color: $vwui-color-text-base;
    }
}

.vwui-btn-grp {
    .vwui-btn, .vwui-btn-no-bg {
        padding-left: 5px;
        padding-right: 5px;
    }
}
