$nav-item-padding: $vwui-space-s $vwui-space-l;
$nav-heading-padding: $vwui-space-m $vwui-space-l;
$nav-item-font-size: $vwui-font-size-m;
$nav-item-active-bg: $vwui-color-nav-active;
$nav-item-active-border: 3px solid $vwui-color-primary-base;

.vwui-nav {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    transition: all .15s;
    background: $vwui-color-nav-overlay;
    opacity: 0;
    visibility: hidden;
    z-index: $vwui-zindex-nav;
    overflow: auto;

    &__container {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: $vwui-size-nav;
        background: $vwui-color-bg-base;
        border-right: $vwui-border-outside;
        flex-direction: column;
        z-index: 10;

        transform: translateX(-100%);
        transition: transform .15s;
    }

    &__overlay {
        width: 100vw;
        height: 100vh;
    }

    &__main {
        flex: 1;
        padding: $vwui-space-component-vertical 0;
    }

    &__bottom {
        border-top: $vwui-border-inside;
        padding: $vwui-space-component-vertical 0;
    }

    &.is-open {
        opacity: 1;
        visibility: visible;

        .vwui-nav__container {
            transform: translateX(0);
        }
    }

    &.is-closed {
        opacity: 0;
        visibility: hidden;

        .vwui-nav__container {
            transform: translateX(-100%);
        }
    }

    @media screen and (min-width: $vwui-mq-nav-toggle) {
        top: $vwui-size-header;
        width: $vwui-size-nav;
        background: none;
        opacity: 1;
        visibility: visible;

        &__overlay {
            visibility: hidden;
            width: 0;
            height: 0;
        }

        &__container {
            transform: translateX(0);
        }
    }
}

.vwui-nav-list {
    padding: 0;
    margin: 0;
    font-size: $nav-item-font-size;
    font-weight: $vwui-font-weight-secondary;
    font-family: $vwui-font-secondary;

    &__header {
        padding: $nav-heading-padding;
        color: $vwui-color-text-label;
    }

    &__item {
        display: block;
        padding: $nav-item-padding;
        color: #47586b;
        border-left: 3px solid transparent;

        transition: all $vwui-transition-base;

        &:hover {
            text-decoration: none;
            color: $vwui-color-text-base;
        }

        &:hover:not(.is-active) {
            color: $vwui-color-text-base;
            background: $vwui-color-nav-active;
        }

        &.is-active {
            background: $vwui-color-nav-active;
            border-left: $vwui-border-nav-active;
        }
    }

    .vwui-nav-badge {
        position: relative;
        display: block;
        background-size: contain;
        text-decoration: none;

        .vwui-nav-badge-item {
            text-align: center;
            padding-top: 6px;
            position: absolute;
            background:red;
            border-radius:50%;
            height: 28px;
            width: 28px;
            line-height: 12px;
            color: #fff;
            top: 0;
        }
    }

}
