.app-login-overlay {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;

    &__container {
        position: relative;
        display: flex;
        flex: 1;
        max-width: rem(600px);
        margin: 0 auto;
        padding: 0 $vwui-space-component-horizontal;
        align-items: center;
        justify-content: center;
        z-index: 10;
    }

    &__backdrop {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: $vwui-color-bg-body;
    }
}
