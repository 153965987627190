$btn-size: rem(30px);

.vwui-pagination {
  display: inline-flex;
  align-items: center;

  &__pages {
    color: #9aa5b5;
    font-weight: 500;
  }

  &__btn {
    display: flex;
    width: $btn-size;
    height: $btn-size;
    background-color: $vwui-color-bg-base;
    border: $vwui-border-outside;
    border-radius: $vwui-border-radius-s;
    box-shadow: $vwui-shadow-base;
    color: $vwui-color-text-label;
    vertical-align: top;
    transition: background $vwui-transition-base;

    @include flex-center();

    &:disabled {
        cursor: not-allowed;
        opacity: .5;
    }

    &:hover:not(:disabled) {
      background: $vwui-color-bg-hover;
    }

      &:first-child {
          margin-right: .5rem;
      }

      &:last-child {
          margin-left: .5rem;
      }
  }

  &__list {
    --pagination-list-space: 0 #{rem(15px)};

    padding: 0 rem(15px);
    list-style: none;
  }

  &__list-item {
    --pagination-list-item-space: 0 #{rem(5px)};

    display: inline-flex;
    padding: 0 rem(5px);

    &.is-active {
      color: $vwui-color-text-primary;
      font-weight: $vwui-font-weight-secondary;
    }
  }

  &__link {
    display: block;
    color: $vwui-color-text-label;

    &:hover {
      text-decoration: none;
    }
  }
}
