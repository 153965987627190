.vwui-multi-select-table {
    &__checkbox-column {
        cursor: default;
        padding-right: 0 !important;

        & + td, & + th {
            padding-left: 1.2rem;
        }
    }

    &--unclickable {
        cursor: default;
    }
}

.vwui-multi-select-table__actions {
    display: flex;
    align-items: center;

    .vwui-icon-btn {
        display: flex;
        margin-right: rem(16px);

        &.is-danger {
            --icon-fill: #{$vwui-color-danger-base};
        }
    }
}

.align-middle {
    vertical-align: middle;
}
