@use "sass:math";

$vwui-font-size-root: math.div($vwui-font-size-default, 16px) * 1em;

$vwui-font-size-base: 1rem;
$vwui-font-size-m: rem(17px);
$vwui-font-size-l: rem(20px);
$vwui-font-size-xl: rem(36px);
$vwui-font-size-xxl: rem(48px);

$vwui-font-size-h1: $vwui-font-size-xxl;
$vwui-font-size-h2: $vwui-font-size-xl;
$vwui-font-size-h3: $vwui-font-size-l;
$vwui-font-size-h4: $vwui-font-size-m;
$vwui-font-size-h5: $vwui-font-size-base;
$vwui-font-size-h6: $vwui-font-size-base;
