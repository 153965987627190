.app-file-upload {


    &__file-drop {
        display: block;
        padding: $vwui-space-component;

        .ngx-file-drop__drop-zone {
            position: relative;
            height: auto!important;
            border-radius: $vwui-border-radius-base !important;
            border: 2px dotted $vwui-color-border-outside !important;
        }

        .ngx-file-drop__content {
            height: auto!important;
            display: block!important;
        }

        &__browse-btn {
            width: 100%;
            height: 3rem;
            appearance: none;
            border: none;
            background: none;
            color: $vwui-color-text-label
        }
    }
}
