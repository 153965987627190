.icon-link {
  display: flex;
  width: rem(20px);
  font-size: rem(20px);
  color: $vwui-color-text-label;
  background: none;
  border: 0;

  &:hover {
    color: $vwui-color-text-base;
  }
}
