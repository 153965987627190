html {
    font-size: $vwui-font-size-root;
}

body {
    font-family: $vwui-font-body;
    background: $vwui-color-bg-body;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overscroll-behavior: none; // Disable overscroll

    padding-top: $vwui-size-header;

    transition: margin-right $vwui-transition-ease $vwui-transition-timing;
    will-change: margin-right;

    overflow-x: hidden;

    @media screen and (min-width: $vwui-mq-nav-toggle) {
        margin-left: $vwui-size-nav;
    }

    &.aside-open {
        margin-right: $vwui-size-aside;
    }

    &.actionbar-visible {
        .vwui-container {
            padding-bottom: $vwui-size-actionbar;
        }
    }
}

strong {
    font-weight: $vwui-font-weight-secondary;
}
