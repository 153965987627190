.vwui-actionbar {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: $vwui-size-actionbar;
    padding: 0 $vwui-space-xl;
    background-color: $vwui-color-bg-base;
    box-shadow: 0 rem(-10px) rem(20px) 0 rgba(0, 0, 0, .05);
    align-items: center;
    justify-content: flex-end;
    z-index: 9999;

    .vwui-btn {
        &:not(:last-child) {
            margin-right: $vwui-space-s;
        }
    }
}
