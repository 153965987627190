$nav-toggle-size: rem(30px);
$nav-toggle-icon-size: rem(22px);

.vwui-header {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: $vwui-size-header;
    background: $vwui-color-bg-base;
    border-bottom: $vwui-border-outside;
    font-family: $vwui-font-secondary;
    align-items: center;
    z-index: $vwui-zindex-header;
    pointer-events: none;

    &__left {
        display: flex;
        flex: 1;
        padding-left: $vwui-space-l;
        align-items: center;
    }

    &__right {
        position: relative;
        padding-right: $vwui-space-l;
    }

    &__logo {
        display: flex;
        align-items: center;
    }

    &__nav-toggle {
        display: inline-flex;
        background: none;
        border: 0;
        align-items: center;
        width: $nav-toggle-size;
        height: $nav-toggle-size;
        margin: 0;
        padding: 0;
        pointer-events: all;

        app-icon {
            --icon-size: #{$nav-toggle-icon-size};
        }

        @media screen and (min-width: $vwui-mq-nav-toggle) {
            display: none;
        }
    }
}
