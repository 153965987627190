.vwui-avatar {
  display: flex;
  width: rem(40px);
  height: rem(40px);
  background-color: $vwui-color-primary-base;
  border-radius: 100%;
  color: $vwui-color-text-contrast;

  @include flex-center();
}
