.vwui-tag {
    border-radius: 4px;
    font-family: Heebo, Arial, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.7);
    padding: 2px 8px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
    margin-bottom: 3px;

    &--default {
        border: 1px solid #e1e5f1;
    }

    &--1 {
      background-color: #ffe588;
    }

    &--2 {
        background-color: #80f2c4;
    }

    &--3 {
        background-color: #fdc2b3;
    }

    &--4 {
        background-color: #c2b8f2;
    }

    &--5 {
        background-color: #7fe3f4;
    }

    &--6 {
        background-color: #c6d4d9;
    }

    &--7 {
        background-color: #847643;
        color: #ffffff;
    }

    &--8 {
        background-color: #23865e;
        color: #ffffff;
    }

    &--9 {
        background-color: #936464;
        color: #ffffff;
    }

    &--10 {
        background-color: #61588b;
        color: #ffffff;
    }

    &--11 {
        background-color: #457f89;
        color: #ffffff;
    }

    &--12 {
        background-color: #00384c;
        color: #ffffff;
    }
}

.tag-select {
    &.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
        display: flex;
        height: 2rem;
        border: 0;
        padding: 0;

        &:hover {
            background: none;
            cursor: pointer;
        }

        .vwui-tag {
            margin-right: 0;
            margin-bottom: 0;

            app-icon {
                margin-left: 8px;
                top: 2px;
                position: relative;
            }
        }
    }

    .ng-option .vwui-tag {
        padding: 0;
        display: inline-block;
        vertical-align: middle;
        background: none;
        font-size: 15px;
        color: rgba(0, 0, 0, 0.7);

        &__ text {
            position: relative;
            bottom: 2px;
        }

        &::before {
            width: 24px;
            height: 24px;
            border-radius: 4px;
            content: "";
            display: inline-block;
            margin-right: 12px;
            position: relative;
            top: 4px;
        }

        &--default {
            border: 1px solid #e1e5f1;
        }

        &--1::before {
            background-color: #ffe588;
        }

        &--2::before {
            background-color: #80f2c4;
        }

        &--3::before {
            background-color: #fdc2b3;
        }

        &--4::before {
            background-color: #c2b8f2;
        }

        &--5::before {
            background-color: #7fe3f4;
        }

        &--6::before {
            background-color: #c6d4d9;
        }

        &--7::before {
            background-color: #847643;
        }

        &--8::before {
            background-color: #23865e;
        }

        &--9::before {
            background-color: #936464;
        }

        &--10::before {
            background-color: #61588b;
        }

        &--11::before {
            background-color: #457f89;
        }

        &--12::before {
            background-color: #00384c;
        }
    }
}
