.vwui-container {
    position: relative;
    max-width: $vwui-size-container;
    margin: 0 auto;

    &.is-small {
        max-width: $vwui-size-container-small;
    }

    &.is-full {
        max-width: 100%;
    }
}
