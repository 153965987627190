@media print {
    .modal-open .vwui-main {
        display: none;
    }

    .vwui-header__nav-toggle,
    .vwui-btn,
    .vwui-modal-close,
    .modal-backdrop {
        display: none;
    }

    .modal {
        position: relative;
        top: initial;
        left: initial;
        padding: 3.333rem;
    }

    .modal-content {
        border: 1px solid #d8dee5;
    }

    .modal-dialog {
        max-width: 100%;
        display: block;
    }
}
