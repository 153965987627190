app-question-list-item {
    display: block;

    &.sortable-ghost {
        transition: all $vwui-transition-base;

        .vwui-list-item {
            background: $vwui-color-bg-hover;
            border-bottom: 1px solid $vwui-color-primary-base;
            border-left-color: transparent;

            &__content {
                opacity: 0;
            }
        }
    }

    .vwui-badge {
        margin-right: 5px;
    }

    &.sortable-drag {
        .vwui-list-item {
            background: $vwui-color-bg-base;
            box-shadow: $vwui-shadow-base;
            will-change: transform;
        }
    }
}

.vwui-list {
    padding: 0;

    &__action {
        padding: $vwui-space-component;
    }

    &.is-sorting {
        .vwui-list-item {
            // Show grabbing cursor if sorting is active
            cursor: grabbing !important;
        }
    }
}

.vwui-modal-content {
    .vwui-list-item-input__action-btn {
        width: 100%;
        border-radius: $vwui-border-radius-base;
    }
}

.vwui-list-item {
    border-bottom: $vwui-border-inside;
    background-color: $vwui-color-bg-base;
    user-select: none;

    &.has-progress {
        --percentage: calc(var(--progress, 0) * 1%);
        background: linear-gradient(
            to right,
            $vwui-color-bg-hover var(--percentage),
            $vwui-color-bg-base var(--percentage)
        );
    }

    &.has-action:not(.is-selected) {
        cursor: pointer;

        &:hover {
            .vwui-list-item__content {
                background-color: $vwui-color-bg-hover;
            }
        }
    }

    &.is-selected {
        .vwui-list-item__content {
            background-color: $vwui-color-bg-hover;
            border-left-color: $vwui-color-primary-base;
        }
    }

    &.collapsible-open {
        .vwui-list-item__content-collapsible {
            display: block;
        }
    }

    &__content {
        display: flex;
        position: relative;
        justify-content: space-between;
        align-items: center;
        transition: all $vwui-transition-base;
        height: rem(70px);
        padding: $vwui-space-component;
        border-left: 3px solid transparent;

        &--auto-height {
            height: auto;
            min-height: rem(70px);
        }
    }

    &__content-collapsible {
        display: none;
        padding: $vwui-space-component;
        border-left: 3px solid $vwui-color-border-outside;
    }

    &__settings {
        display: flex;
        align-items: center;
        position: absolute;
        height: 100%;
        top: 0;
        right: 3.4rem;
        padding-right: 1rem;
        border-right: 1px solid $vwui-color-border-outside;
    }

    &__settings-item {
        display: inline-flex;
        position: relative;
        margin-left: 0.75rem;
        color: $vwui-color-text-label;
    }

    &__settings-required {
        position: absolute;
        top: -0.5rem;
        right: -0.4rem;
        font-size: 0.85rem;
    }
}

.vwui-list-item-input {
    display: flex;
    margin-bottom: $vwui-space-s;

    .vwui-input {
        padding-left: $vwui-space-s;
        padding-right: 60px;
        border-right-width: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        &:focus {
            border-right-width: 1px;
        }
    }

    &__group {
        position: relative;
        flex: 1;
    }

    &__sort {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        padding-right: rem(8px);
    }

    &__sort-btn {
        display: inline-flex;
        align-items: center;
        height: 100%;
        border: 0;
        background: none;
        margin-left: rem(4px);
        color: $vwui-color-text-label;

        &:not(:disabled):hover {
            color: $vwui-color-text-base;
        }

        &:disabled {
            cursor: not-allowed;
        }
    }

    &__actions {
        display: flex;
    }

    &__action {
        display: inline-flex;
        width: rem(45px);
        height: rem(45px);
        background: $vwui-color-bg-base;
        border: $vwui-border-outside;
        border-top-right-radius: $vwui-border-radius-base;
        border-bottom-right-radius: $vwui-border-radius-base;
    }

    &__action-checkbox {
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-right-radius: $vwui-border-radius-base;
        border-bottom-right-radius: $vwui-border-radius-base;
        border-left: 0;

        > app-checkbox {
            width: 20px;
            margin-bottom: .25rem;
        }
    }

    &__action-btn {
        color: $vwui-color-text-label;

        &:hover {
            color: $vwui-color-text-base;
        }

        &--delete {
            border-radius: $vwui-border-radius-base;
            margin-left: $vwui-space-s;

            &:hover {
                color: $vwui-color-danger-base;
            }
        }

        span {
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
        }

        &:hover {
            background-color: $vwui-color-bg-hover;
        }

        &.is-active  {
            color: $vwui-color-primary-base;
            border-color: $vwui-color-primary-base;
            cursor: default;
        }

        &.is-active:first-child + & {
            border-left-color: $vwui-color-primary-base;
        }

        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }
}

.vwui-list-item-form {
    display: inline;
}

.question-choices-label {
    display: flex;
    align-items: center;

    > span {
        font-size: 12px;
        margin-left: auto;
        margin-right: 54px;
    }
}
