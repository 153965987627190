// Brand color
$vwui-color-brand: #d8dee5;

// Contextual colors
$vwui-color-primary-base: #008cbd;
$vwui-color-primary-dark: #007da9;
$vwui-color-success-base: #50c892;
$vwui-color-success-dark: #47af80;
$vwui-color-danger-base: #db5353;
$vwui-color-danger-dark: #d23a41;
$vwui-color-warning-base: #ff9e7e;

// Border colors
$vwui-color-border-outside: #d8dee5;
$vwui-color-border-inside: #e9edf3;
$vwui-color-border-dark: #222f3e;

// Background colors
$vwui-color-bg-body: #f3f4f6;
$vwui-color-bg-base: #fff;
$vwui-color-bg-hover: #f2f6fa;
$vwui-color-bg-disabled: #e9edf3;
$vwui-color-bg-inactive: #e9edf3;

// Text colors
$vwui-color-text-base: #222f3e;
$vwui-color-text-primary: $vwui-color-primary-base;
$vwui-color-text-label: #9aa5b5;
$vwui-color-text-disabled: #9aa5b5;
$vwui-color-text-contrast: #fff;

// Icon colors
$vwui-color-icon: #9aa5b5;

// Shadow colors
$vwui-color-shadow-base: rgba(0, 0, 0, 0.05);

// Nav colors
$vwui-color-nav-active: rgba(0, 88, 167, 0.05);
$vwui-color-nav-overlay: rgba(0, 0, 0, 0.15);

// Color map to generate specific utility helper classes
$vwui-colors: (
  primary: $vwui-color-primary-base,
  success: $vwui-color-success-base,
  danger: $vwui-color-danger-base,
  label: $vwui-color-text-label,
  contrast: $vwui-color-text-contrast
)
