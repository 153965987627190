.ng-select {
  &.ng-select-single {
    .ng-select-container {
      height: rem(45px);
    }

    .ng-select-container .ng-value-container .ng-input {
      top: rem(10px);
      padding: 0 $vwui-space-component-horizontal-s;
    }
  }

  &.ng-select-opened {
      z-index: 10000;
  }

  &.ng-select-opened > .ng-select-container {
    border-color: $vwui-color-primary-base;
  }

  .ng-select-container {
    border-color: $vwui-color-border-outside;
    min-height: rem(45px);

    .ng-value-container {
      padding: 0 $vwui-space-component-horizontal-s;

      .ng-placeholder {
        color: $vwui-color-text-label;
        font-family: $vwui-font-base;
      }
    }
  }

  .ng-input::placeholder {
    color: $vwui-color-text-label;
  }

  .ng-dropdown-panel {
    border-color: $vwui-color-primary-base;

    .ng-dropdown-panel-items .ng-option {
      padding: 0 $vwui-space-component-horizontal-s;
    }
  }

  &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    box-shadow: none;
    border-color: $vwui-color-primary-base;
  }
}

.has-error .ng-select .ng-select-container {
  border-color: $vwui-color-danger-base;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
  font-weight: normal;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding: 0 $vwui-space-component-horizontal-s;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
}

.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: $vwui-space-component-vertical-s $vwui-space-component-horizontal-s;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  display: flex;
  height: rem(30px);
  border-radius: rem(5px);
  background-color: $vwui-color-bg-base;
  border: $vwui-border-outside;
  padding: 0 rem(10px);
  align-items: center;
  font-size: $vwui-font-size-base;
  margin-bottom: rem(2px);

  &:hover {
    background-color: $vwui-color-bg-hover;
  }
}

.ng-select-clear-icon {
  display: inline-flex;
  align-items: center;
  margin-left: rem(10px);
  cursor: pointer;

  app-icon {
    --icon-fill: #{$vwui-color-primary-base};
    --icon-size: #{rem(16px)};
  }
}

.ng-select.ng-select-disabled {
    .ng-select-container {
        cursor: not-allowed;
        background-color: #e9edf3;

        .ng-value-container .ng-input>input {
            cursor: not-allowed;
        }
    }
}
