.vwui-filter {
    display: flex;
    align-items: center;
    flex-flow: row wrap;

    &__item {
        display: flex;
        font-size: $vwui-font-size-base;
        font-family: $vwui-font-body;
        font-weight: $vwui-font-weight-secondary;
        color: $vwui-color-text-label;
        margin-right: $vwui-space-m;
        height: rem(35px);
        align-items: center;
        padding: 0 $vwui-space-s;
        border: $vwui-border-base transparent;
        transition: all $vwui-transition-base;
        cursor: pointer;

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            color: darken($vwui-color-text-label, 25%);
        }

        &.is-active {
            font-weight: $vwui-font-weight-secondary;
            color: $vwui-color-text-primary;
            border-color: $vwui-color-primary-base;
            border-radius: $vwui-border-radius-base;
            box-shadow: $vwui-shadow-base;
            cursor: default;
        }
    }
}
