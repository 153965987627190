.vwui-spinner {
    --loader-color: #{rgba($vwui-color-text-label, 0.2)};
    --loader-color-spinner: #{rgba($vwui-color-text-label, 1.0)};
    --spinner-width: #{rem(45px)};
    --spinner-loader-width: #{rem(20px)};
    --spinner-loader-border-width: #{rem(3px)};

    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--spinner-width);
    height: var(--spinner-width);
    border-right: $vwui-border-base var(--icon-border-color);

    &::after {
        display: block;
        content: "";
        width: var(--spinner-loader-width);
        height: var(--spinner-loader-width);
        border-top: var(--spinner-loader-border-width) solid var(--loader-color);
        border-right: var(--spinner-loader-border-width) solid var(--loader-color);
        border-bottom: var(--spinner-loader-border-width) solid var(--loader-color);
        border-left: var(--spinner-loader-border-width) solid var(--loader-color-spinner);
        border-radius: 100%;
        animation: spinner 1.1s infinite linear;
    }

    &--large {
        --spinner-loader-width: #{rem(40px)};
        --spinner-loader-border-width: #{rem(4px)};
    }

    &--height-auto {
        height: auto;
    }

    @keyframes spinner {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }
}
