app-job-location-select-map {
    position: relative;
    display: block;
    width: 100%;
    padding-top: 60%;
    .job-location-select-map-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
