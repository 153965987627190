/**
  Table component styling
 */

// Table container
.vwui-table-container {
    background: $vwui-color-bg-base;
    box-shadow: $vwui-shadow-base;
    border-radius: $vwui-border-radius-l;
    border: $vwui-border-outside;
    overflow: hidden;
}

.vwui-table-container-scroll {
    overflow-x: auto;
}

// Table
.vwui-table {
    width: 100%;

    th,
    td {
        padding: $vwui-space-component;
        vertical-align: middle;
    }

    .td-15 {
        max-width: 15vw;
    }

    .td-10 {
        max-width: 10vw;
    }
    .td-15, .td-10 {
        word-wrap: break-word;
    }

    .vwui-table-row {
        display: table-row;

        &:hover {
            text-decoration: none;
        }
    }

    th,
    tr:not(:last-child),
    .vwui-table-row:not(:last-child) {
        border-bottom: $vwui-border-inside;
    }

    tr:focus,
    .vwui-table-row:focus {
        outline: 0;
    }

    th {
        color: $vwui-color-text-label;
        font-weight: $vwui-font-weight-secondary;
        user-select: none;
        vertical-align: top;

        &.sortable {
            cursor: ns-resize;
        }
    }

    &.has-hover tbody {
        tr, .vwui-table-row {
            transition: background $vwui-transition-base;
        }

        tr:hover, .vwui-table-row:hover {
            background: $vwui-color-bg-hover;

            .vwui-table__arrow-icon {
                color: $vwui-color-text-primary;
                transform: translateX(#{rem(5px)});
            }
        }
    }

    &.has-action tbody {
        tr:not(.no-action), .vwui-table-row:not(.no-action) {
            cursor: pointer;
        }
    }

    &.is-vertical {
        th,
        td {
            padding: 0 0 $vwui-space-s 0;
        }

        th,
        tr,
        .vwui-table-row {
            border-bottom: 0;
        }

        th {
            width: rem(250px);
        }
    }

    &__sort-icon {
        position: relative;
        display: inline-flex;
        transition: all .15s ease-in-out;
        opacity: 0;
        top: 2px;

        &.is-ascending {
            transform: rotate(180deg);
            opacity: 1;
        }

        &.is-descending {
            transform: rotate(0);
            opacity: 1;
        }
    }

    &__arrow-icon {
        text-align: right;
        font-size: rem(20px);
        color: $vwui-color-text-label;
        transition: color $vwui-transition-base, transform $vwui-transition-timing-slow $vwui-transition-ease;
        will-change: transform;

        .vwui-icon {
            display: block;
        }
    }

    &__fixed-column-width {
        width: rem(30px);
        padding: 0 rem(20px) !important;
    }

    &__actions {
        display: flex;
        justify-content: flex-end;
    }

    &__large {
        white-space: nowrap;
        min-width: rem(200px);
    }
}

// Table pagination
.vwui-table-pagination {
    display: flex;
    padding: $vwui-space-component;
    border-top: $vwui-border-inside;

    @include flex-center();
}
