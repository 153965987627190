.vwui-modal-header {
  display: flex;
  border-bottom: $vwui-border-inside;
  padding: 0 $vwui-space-component-horizontal;
  height: rem(70px);
  justify-content: space-between;
  align-items: center;
}

.vwui-modal-close {
  cursor: pointer;

  app-icon {
    --icon-size: #{rem(20px)};
    --icon-fill: #{$vwui-color-icon};

    .vwui-icon {
      display: block;
    }
  }

  &:hover {
    app-icon {
      --icon-fill: #{$vwui-color-text-base};
    }
  }
}

.vwui-modal-title {
  font-size: $vwui-font-size-l;
  font-weight: $vwui-font-weight-secondary;
  font-family: $vwui-font-secondary;
  margin: 0;
  padding: 0;
}

.vwui-modal-table {
    .vwui-modal-content {
        padding: 0;
    }
}

.vwui-modal-content {
  padding: $vwui-space-component;
}

.vwui-modal-footer {
  display: flex;
  height: rem(80px);
  border-top: $vwui-border-inside;
  padding: 0 $vwui-space-component-horizontal;
  justify-content: space-between;
  align-items: center;
}


// Bootstrap override
.modal-content {
  border-radius: $vwui-border-radius-base;
  border: 0;
}

.modal-backdrop.show {
  opacity: 0.7;
}
