*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

input,
textarea,
button:focus,
a:focus {
  outline: 0;
}

::placeholder {
  color: $vwui-color-text-label;
}

button {
  cursor: pointer;
}

ul,
ol {
  margin: 0;
}

a,
a:hover {
  color: $vwui-color-text-base;
}

img {
  width: 100%;
  height: auto;
}


@if ($vwui-vendor-bootstrap) {
  .container,
  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}
