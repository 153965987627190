app-project-job-detail .vwui-list-item__content {
    height: auto;
}

.app-project-job-detail {
    &__thumbnail {
        max-height: rem(200);
        width: auto;

        &__answer-image {
            cursor: pointer;
        }
    }

    &__download-button {
        display: block;
        position: absolute;
        right: 15px;
    }
}
