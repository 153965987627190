.vwui-toolbar {
    display: flex;
    margin-bottom: $vwui-space-xl;

    @media(max-width: $vwui-mq-nav-toggle) {
        margin-bottom: $vwui-space-m;
        flex-flow: column nowrap;
    }

    &__left {
        display: flex;
        align-items: center;
        margin: 0 $vwui-space-l 0 0;

        @media(max-width: $vwui-mq-nav-toggle) {
            flex-flow: column nowrap;
            margin: 0 0 $vwui-space-l;
            align-items: flex-start;
        }
    }

    &__right {
        display: flex;
        justify-content: flex-end;
        flex-flow: row wrap;
        flex: 1;
        gap: $vwui-space-s;

        @media(max-width: 767px) {
            flex-flow: column nowrap;
        }

        .vwui-btn {
            margin: 0;
            @media(max-width: 767px) {
                width: 100%;
            }
        }
    }

    &__title {
        font-size: $vwui-font-size-l;
        font-weight: $vwui-font-weight-secondary;
        font-family: $vwui-font-secondary;
        margin: $vwui-space-m 0 0;

        @media(min-width: $vwui-mq-nav-toggle) {
            max-width: 25vw;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin: 0 0 0 $vwui-space-m;
        }
    }
}
